import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Approval from './component/approval.jsx';
import { HashRouter ,Switch,Route,Link } from "react-router-dom";

import React, { useState } from 'react';

var FormData = require('form-data');
function App() {
  
  return (
    <HashRouter>
    <div className="App">
          <Switch>
            <Route path="/approval/setuju/:no_kontrak/:penerima" component={Approval} />
            <Route path="/approval/tidak_setuju/:no_kontrak/:penerima" component={Approval} />
          </Switch>
    </div>
    </HashRouter>
  );
}

export default App;
