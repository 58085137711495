import React, { Component } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";

export default class Approval extends Component {

    constructor(props) {
        super(props) //since we are extending class Table so we have to use super in order to override Component class constructor
        this.state = { //state is by default an object
           data: null,
           nama_penerima:"",
           order:"",
           loading:true
        }
        this.Message = this.Message.bind(this);
     }

     componentDidMount(){
        this.Message();
     }
     
     Message = ()=> {
      var no_kontrak =  this.props.match.params.no_kontrak;
      var penerima =  this.props.match.params.penerima;
      var path = this.props.match.path;
      if(path == '/approval/setuju/:no_kontrak/:penerima'){
         var link = '/approval/setuju';
      }else{
         var link = '/approval/tidak_setuju';
      }
      var link_api = window.api+link; 
      console.log(link_api);
      axios.get(link_api, {
         params: {
           id: no_kontrak,
           penerima:penerima
         }
       })
       .then( (response)=> {
         console.log(response.data);
         this.insertData(response.data);
         this.setState({
          loading:false
         })
       })
       .catch(function (error) {
         console.log(error);
         this.setState({
          loading:false
         })
       })
     }

     insertData = async (data)=>{
      this.setState({data:data.Message,nama_penerima:data.penerima,order:data.kode});
     }

     onClick (){
      window.close();
     }

    render() {
        return (
          <>
            {this.state.loading && <Spinner animation="border" role="status">
              <span className="visually-hidden"></span>
            </Spinner>}
          <div className="outer">
             <div className="inner">
             {this.state.data && 
             <div className="inner-form">
               <h1 class="text-center">{this.state.data}</h1>
               <p>{this.state.nama_penerima}</p>
               <p>{this.state.order} telah diterima, silahkan tutup browser anda untuk kembali ke WhatsApp</p>
             </div>
             }
             </div>
         </div>
          </>
        );
    }
}